import React from 'react'
import PropTypes from 'prop-types'

export const GraphRulePreferencesContext = React.createContext();

export const DEFAULT_GRAPH_RULE_PREFERENCES = {
    FirstGroupInboundCommRule: {
        global:false,
        enabled: 'true',
        rule_message: 'This is their first email to {recipients}.',
        rule_message_single: 'This is their first email to you.',
    },
    FirstGroupOutboundCommRule: {
        global:false,
        enabled: 'true',
        rule_message: '{recipients} never replied to this person.',
        rule_message_single: 'You\'ve never replied to this person.',
    },
    FirstCompanyInboundCommRule: {
        global:false,
        enabled: 'true',
        rule_message: 'This is their first email to your company.',
        rule_message_single: 'This is their first email to your company.',
    },
    FirstCompanyOutboundCommRule: {
        global:false,
        enabled: 'true',
        rule_message: 'No employee in your company has ever replied to this person.',
        rule_message_single: 'No employee in your company has ever replied to this person.',
    },
    SimilarNameWithinCompanyRule: {
        global:false,
        enabled: 'true',
        rule_message: 'Similar name as someone in your company.',
        rule_message_single: 'Similar name as someone in your company.',
    },
    SimilarNameDifferentEmailRule: {
        global:false,
        enabled: 'true',
        rule_message: 'Similar name as someone you\'ve contacted.',
        rule_message_single: 'Similar name as someone you\'ve contacted.',
    },
    YoungDomainRule: {
        global:false,
        enabled: 'true',
        rule_message: 'Domain was created recently.',
        rule_message_single: 'Domain was created recently.',
    },
    BlacklistedDomainRule: {
        global:false,
        enabled: 'true',
        rule_message: 'This domain was marked as dangerous. Do not click any links or respond.',
        rule_message_single: 'This domain was marked as dangerous. Do not click any links or respond.',
    },
    BlacklistedAddressRule: {
        global:false,
        enabled: 'true',
        rule_message: 'This sender was marked as dangerous. Do not click any links or respond.',
        rule_message_single: 'This sender was marked as dangerous. Do not click any links or respond.',
    },
    SPFRecordExistsRule: {
        global:false,
        enabled: 'true',
        rule_message: 'The sender\'s email address couldn\'t be verified.',
        rule_message_single: 'The sender\'s email address couldn\'t be verified.',
    },
    SPFSenderFailedRule: {
        global:false,
        enabled: 'true',
        rule_message: 'The sender\'s email address couldn\'t be verified.',
        rule_message_single: 'The sender\'s email address couldn\'t be verified.',
    },
    PunyEmailRule: {
        global:false,
        enabled: 'true',
        rule_message: 'This person\'s address has obscure characters.',
        rule_message_single: 'This person\'s address has obscure characters.',
    },
    PunyNameRule: {
        global:false,
        enabled: 'true',
        rule_message: 'This person\'s name has obscure characters.',
        rule_message_single: 'This person\'s name has obscure characters.',
    },
    PunySubjectRule: {
        global:false,
        enabled: 'true',
        rule_message: 'The subject has obscure characters.',
        rule_message_single: 'The subject has obscure characters.',
    },
    AddressLengthRule: {
        global:false,
        enabled: 'true',
        rule_message: 'This person\'s address is obscure.',
        rule_message_single: 'This person\'s address is obscure.',
    },
    PersonalEmailRule: {
        global:false,
        enabled: 'true',
        rule_message: 'This is a personal email address.',
        rule_message_single: 'This is a personal email address.',
    },
    PersonalInternationalEmailRule: {
        global:false,
        enabled: 'true',
        rule_message: 'This is an obscure personal email address.',
        rule_message_single: 'This is an obscure personal email address.',
    },
    MarketingSpamRule: {
        global:false,
        enabled: 'true',
        rule_message: 'This is a marketing or notification email.',
        rule_message_single: 'This is a marketing or notification email.',
    },
    SalesSpamRule: {
        global:false,
        enabled: 'true',
        rule_message: 'This person is known to use individual trackers.',
        rule_message_single: 'This person is known to use individual trackers.',
    },
    RecipientInclusionRule: {
        global:false,
        enabled: false,
        rule_message: 'Someone new is on this email.',
        rule_message_single: 'Someone new is on this email.',
    },
};

/**
 * All possible actions you can take on {@link GraphRulePreferencesContext}
 */
export const GraphRulePrefsAction = Object.freeze({
    // Write the entire state (usually for initial loading)
    WRITE_WHOLE_STATE: 'ACTION_WRITE_WHOLE_STATE',
    // Enable/disable the graph rule
    ENABLE_DISABLE: 'ACTION_ENABLE_DISABLE',
    // Change the rule_message
    RULE_MESSAGE: 'ACTION_RULE_MESSAGE',
    // Change the rule_message_single
    RULE_MESSAGE_SINGLE: 'ACTION_RULE_MESSAGE_SINGLE',
    // Reset both rule_message and rule_message_single to their defaults
    RULE_MESSAGE_DEFAULTS: 'ACTION_RULE_MESSAGE_DEFAULTS'
})

export const graphRulePrefReducer = (state, action) => {
    switch (action.type) {
        case GraphRulePrefsAction.WRITE_WHOLE_STATE:
            return action.payload
        case GraphRulePrefsAction.ENABLE_DISABLE:
            return {
                ...state,
                [action.payload.ruleName]: {
                    ...state[action.payload.ruleName],
                    enabled: action.payload.value,
                },
            }
        case GraphRulePrefsAction.RULE_MESSAGE:
            return {
                ...state,
                [action.payload.ruleName]: {
                    ...state[action.payload.ruleName],
                    rule_message: action.payload.value,
                },
            }
        case GraphRulePrefsAction.RULE_MESSAGE_SINGLE:
            return {
                ...state,
                [action.payload.ruleName]: {
                    ...state[action.payload.ruleName],
                    rule_message_single: action.payload.value,
                },
            }
        case GraphRulePrefsAction.RULE_MESSAGE_DEFAULTS:
            return {
                ...state,
                [action.payload.ruleName]: {
                    ...state[action.payload.ruleName],
                    rule_message: DEFAULT_GRAPH_RULE_PREFERENCES[action.payload.ruleName].rule_message,
                    rule_message_single: DEFAULT_GRAPH_RULE_PREFERENCES[action.payload.ruleName].rule_message_single,
                },
            }
        default:
            console.log("Unknown action: " + action.type);
            return DEFAULT_GRAPH_RULE_PREFERENCES;
    }
}

const GraphRulePrefsContextProvider = ({ children }) => {
    const [state, dispatch] = React.useReducer(graphRulePrefReducer, DEFAULT_GRAPH_RULE_PREFERENCES);
    return (
        <GraphRulePreferencesContext.Provider value={[state, dispatch]}>
            {children}
        </GraphRulePreferencesContext.Provider>
    );
}
GraphRulePrefsContextProvider.propTypes = {
    children: PropTypes.node,
}
export default GraphRulePrefsContextProvider
