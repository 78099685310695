import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { makeStyles, Button } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    tab: {
        fontSize: '16px',
        lineHeight: '22px',
        fontWeight: 600,
        marginBottom: '6px',
    },
    selectedTab: {
        color: theme.palette.primary.main
        // theme.palette.type === 'light'
        //     ? theme.palette.primary.main
        //     : darkTheme.palette.primary.main,
    },
    unselectedTab: {
        color: theme.colors[6]
        // theme.palette.type === 'light' ? theme.colors[6] : darkTheme.colors[6],
    },
    border: {
        width: '100%',
        height: '2px',
        backgroundColor: theme.palette.primary.main,
        // theme.palette.type === 'light'
        // ? theme.palette.primary.main
        // : darkTheme.palette.primary.main,
        borderRadius: '2px',
    },
    smallTab: {
        fontSize: 14,
    },
}))

const Tabs = ({ value, options, onChange, style, smallTab, tabStyle, }) => {
    const classes = useStyles()

    return (
        <div
            style={{
                display: 'flex',
                ...style,
            }}
        >
            {options.map((option, idx) => (
                <div
                    key={option.value}
                    className={tabStyle}
                    style={{
                        marginRight: idx === options.length - 1 ? 0 : '20px',
                        cursor: 'pointer',
                    }}
                >
                    <Button id={`tab-option-${option.value}`}
                        className={classnames(classes.tab, {
                            [classes.selectedTab]: value === option.value,
                            [classes.unselectedTab]: value !== option.value,
                            [classes.smallTab]: smallTab,
                        })}
                        onClick={() => {
                            if (onChange) {
                                onChange(option.value)
                            }
                        }}
                        disabled={option.disabled}
                    >
                        {option.label}
                    </Button>
                    {value === option.value && <div className={classes.border} />}
                </div>
            ))}
        </div>
    )
}
Tabs.propTypes = {
    value: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    style: PropTypes.object,
    smallTab: PropTypes.bool,
    tabStyle: PropTypes.string,
}
export default Tabs
