import React from 'react'
import Settings from '../Settings/Settings'
import ProtectedViewLayout from '../App/ProtectedViewLayout';


const SettingsPage = ({ location }) => {
    return (
        <ProtectedViewLayout location={location}>
            <Settings locationHash={location.hash} />
        </ProtectedViewLayout>
    );
}

export default SettingsPage