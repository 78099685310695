import React from 'react'
import PropTypes from 'prop-types'
import {
    FormControl,
    FormControlLabel,
    Typography,
    Radio,
    RadioGroup,
    FormGroup,
    Switch
} from '@material-ui/core'
import SettingsCard from '@mc/common/components/Cards/SettingsCard'

const StatusCard = ({
    emailAlertsStatus = 'false',
    requireHeader = 'false',
    updateAndSaveSetting,

}) => {

    const handleUpdateAndSaveSetting = (settingKey, settingValue) => {
        if (updateAndSaveSetting) {
            updateAndSaveSetting(settingKey, settingValue)
        }
    }
    const getBooleanHeaderReq = () => {return requireHeader === 'true'}
    return (
        <SettingsCard
            headerText={'Status'}
        >
            <FormControl component="fieldset">
                <RadioGroup value={emailAlertsStatus} onChange={(elem) => handleUpdateAndSaveSetting('enable_graph_rule_alerts', elem.target.value)}>
                    <FormControlLabel value="true" control={<Radio color="primary" />} label={
                        <>
                            <Typography style={{ fontWeight: 600 }} color="textPrimary" variant="body2">
                                Enabled
                            </Typography>
                        </>
                    } />
                    <FormControlLabel value="learning" control={<Radio color="primary" />} label={
                        <>
                            <Typography style={{ fontWeight: 600 }} color="textPrimary" variant="body2">
                                Learning
                            </Typography>
                        </>
                    } />
                    <FormControlLabel value="false" control={<Radio color="primary" />} label={
                        <>
                            <Typography style={{ fontWeight: 600 }} color="textPrimary" variant="body2">
                                Disabled
                            </Typography>
                        </>
                    } />
                </RadioGroup>
            </FormControl>
            <Typography style={{ fontWeight: 600, paddingTop: 10 }} color="textPrimary" variant="body2">
                Require X-Redata-CodeBreaker-Enabled header
            </Typography>
            <Typography color="textPrimary" variant="body2">
                When selected this header must be present with a value of true or CyberGraph Dynamic Banners will not fire when enabled.
            </Typography>
            <FormGroup>
                <FormControlLabel
                    control={<Switch color="primary" checked={getBooleanHeaderReq()} onChange={() => handleUpdateAndSaveSetting('header_required', (!getBooleanHeaderReq()).toString())} />}
                    label={getBooleanHeaderReq() ? 'Enabled' : 'Disabled'}
                />
            </FormGroup>
        </SettingsCard>
    )
}

StatusCard.propTypes = {
    emailAlertsStatus: PropTypes.string,
    requireHeader: PropTypes.bool,
    updateAndSaveSetting: PropTypes.func,
}


export default StatusCard