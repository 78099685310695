import React from 'react'
import PropTypes from 'prop-types'
import { Box, Grid, makeStyles, } from '@material-ui/core'
import { getCodebreakerSettings, updateCodebreakerSetting, getGraphRules, updateGraphRule, getSSOState, updateSSOState } from '../../api/settings'
import { AuthenticationContext } from '../../App/AuthenticationContext'
import { DEFAULT_EMAIL_ALERT_SETTINGS, DEFAULT_GRAPH_CUSTOMIZATIONS } from '../constants'
import InlineEmailAlertsCard from './EmailAlertsTabCards/InlineEmailAlertsCard'
import RuleCustomizationsCard from './EmailAlertsTabCards/RuleCustomizationsCard'
import StatusCard from './EmailAlertsTabCards/StatusCard'
import WarningsCard from './EmailAlertsTabCards/WarningsCard'
import { 
    GraphRulePrefsAction, 
    GraphRulePreferencesContext, 
    DEFAULT_GRAPH_RULE_PREFERENCES } from './EmailAlertsTabCards/GraphRulePreferencesContext'
import SSOCard from './EmailAlertsTabCards/SSOCard'


const useStyles = makeStyles((theme) => ({
    //This prop should be abstracted 
    summaryGrid: {
        flexGrow: 1,
    },

}))

const  useIsMountedRef = () => {  
    const isMountedRef = React.useRef(null)
    React.useEffect(() => {    
        isMountedRef.current = true
        return () => isMountedRef.current = false
    })
    
    return isMountedRef
}

const EmailAlertsTab = ({ triggerToast }) => {
    const classes = useStyles()
    const [authState,] = React.useContext(AuthenticationContext)
    const [, dispatchGraphRulePrefs] = React.useContext(GraphRulePreferencesContext)
    const [emailAlertSettings, setEmailAlertSettings] = React.useState(DEFAULT_EMAIL_ALERT_SETTINGS)
    const [graphRuleCustomizations, setGraphRuleCustomizations] = React.useState(DEFAULT_GRAPH_CUSTOMIZATIONS)
    const [ssoState, setSSOState] = React.useState('')
    const isMountedRef = useIsMountedRef()

    const initialGetCodeBreakerSettings = () => {
        getCodebreakerSettings(authState).then(response => {
            setEmailAlertSettings(response.data.codebreaker_settings)
        })
    }

    React.useEffect(initialGetCodeBreakerSettings, [])

    const initialGetGraphRules = () => {
        getGraphRules(authState).then(response => {
            if(isMountedRef.current){
                let customerGraphConfig = response.data.graph_rule_preferences

                let incomingGraphRulePreferences = {}
                Object.entries(DEFAULT_GRAPH_RULE_PREFERENCES).forEach(([ruleName, defaultPreferences]) => {
                    if (customerGraphConfig[ruleName]) {
                        incomingGraphRulePreferences[ruleName] = Object.assign({}, defaultPreferences, customerGraphConfig[ruleName])
                    }
                    else {
                        incomingGraphRulePreferences[ruleName] = defaultPreferences
                    }
                })
                dispatchGraphRulePrefs({type: GraphRulePrefsAction.WRITE_WHOLE_STATE, 
                                        payload: incomingGraphRulePreferences})

                let incomingGraphRuleCustomizations = {}
                Object.entries(DEFAULT_GRAPH_CUSTOMIZATIONS).forEach(([customizationName, defaultCustomization]) => {
                    if (customerGraphConfig[customizationName]) {
                        incomingGraphRuleCustomizations[customizationName] = Object.assign({}, defaultCustomization, customerGraphConfig[customizationName])
                    }
                    else {
                        incomingGraphRuleCustomizations[customizationName] = defaultCustomization
                    }
                })
                setGraphRuleCustomizations(incomingGraphRuleCustomizations)
            }
        })
    }

    React.useEffect(initialGetGraphRules, [])

    const initialGetSSOState = () => {
        getSSOState(authState).then(response => {
            if(isMountedRef.current){
                if (response.data.sso_customers && response.data.sso_customers.length > 0) {
                    setSSOState("true");
                } else {
                    setSSOState("false");
                }
            }
        })
    }

    React.useEffect(initialGetSSOState, [])

    const handleUpdateSetting = (settingKey, settingValue) => {
        setEmailAlertSettings(
            {
                ...emailAlertSettings,
                [settingKey]: settingValue,
            }
        )
    }

    const handleSaveSetting = (settingKey, settingValue) => {
        updateCodebreakerSetting(settingKey, settingValue, authState).then(response => {
            if (response.status === 200 && triggerToast) {
                triggerToast()
            }
        })

    }

    const handleUpdateAndSaveSetting = (settingKey, settingValue) => {
        handleUpdateSetting(settingKey, settingValue)
        handleSaveSetting(settingKey, settingValue)
    }

    const handleUpdateAndSaveGraphRuleCustomization = (customizationName, key, value) => {
        setGraphRuleCustomizations(
            {
                ...graphRuleCustomizations,
                [customizationName]: {
                    ...graphRuleCustomizations[customizationName],
                    [key]: value,
                },

            }
        )

        updateGraphRule(customizationName, key, value, authState).then(response => {
            if (response.status === 200 && triggerToast) {
                triggerToast()
            }
        })
    }

    /**
     * Saves the graph rule preference to the server.
     * @param {string} ruleName name of the rule to update
     * @param {string} preference name of the preference within the rule
     * @param {string} value new value
     */
    const handleSaveGraphRulePreference = (ruleName, preference, value) => {
        updateGraphRule(ruleName, preference, value, authState).then(response => {
            if (response.status === 200 && triggerToast) {
                triggerToast()
            }
        })
    }

    const handleUpdateSSOState = (enabled) => {
        setSSOState(enabled);
        updateSSOState(enabled, authState).then(response => {
            if (response.status === 200 && triggerToast) {
                triggerToast()
            }
        })
    }

    return (
        <Box className={classes.summaryGrid}>
            <Grid container spacing={3}>
                {!authState.isMimeOSCustomer && <Grid item xs={12}>
                    <StatusCard
                        updateAndSaveSetting={handleUpdateAndSaveSetting}
                        requireHeader={emailAlertSettings.header_required}
                        emailAlertsStatus={emailAlertSettings.enable_graph_rule_alerts}
                    />
                </Grid>}
                <Grid item xs={12}>
                    <WarningsCard
                        updateSetting={handleUpdateSetting}
                        saveSetting={handleSaveSetting}
                        updateAndSaveSetting={handleUpdateAndSaveSetting}
                        aiSuppressionStatus={emailAlertSettings.ai_assist}
                        warningType={emailAlertSettings.cloud_graph_alert_type}
                        imageReportButtonText={emailAlertSettings.codebreaker_image_report_safe_text}
                        textReportButtonText={emailAlertSettings.codebreaker_report_text}
                        textMarkSafeButtonText={emailAlertSettings.codebreaker_safe_text}
                        creditText={emailAlertSettings.codebreaker_credit_text}
                    />
                </Grid>
                <Grid item xs={12}>
                    <RuleCustomizationsCard
                        fuzzyNameMatchPrecision={graphRuleCustomizations.FuzzyMatching.precision}
                        fuzzyNameMatchCount={graphRuleCustomizations.MisaddressedRecipientRule.match_count !== null ? parseInt(graphRuleCustomizations.MisaddressedRecipientRule.match_count) : null}
                        updateAndSaveGraphRuleCustomization={handleUpdateAndSaveGraphRuleCustomization}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InlineEmailAlertsCard
                        saveGraphRulePreference={handleSaveGraphRulePreference}
                    />
                </Grid>
                {(authState.isMimeOSCustomer && authState.isImpersonating) && <Grid item xs={12}>
                    <SSOCard
                        ssoEnabled={ssoState}
                        updateAndSaveSSOStatus={handleUpdateSSOState}
                    />
                </Grid>}
            </Grid>
        </Box>
    )
}

EmailAlertsTab.propTypes = {
    triggerToast: PropTypes.func,
}

export default EmailAlertsTab