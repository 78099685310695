import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Box, useTheme, makeStyles } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp, faArrowDown } from '@fortawesome/pro-regular-svg-icons';
import theme from '../../../admin/src/theme';


const colors = {
    up: {
        dark: '#338a3e',
        light: '#00600f',
    },
    down: {
        dark: '#ef5350',
        light: '#d32f2f',
    },
}

const useStyles = makeStyles(() => ({
    wrap: {
        fontSize: 16,
        fontWeight: 600,
        width: 'max-content',
        display: 'flex',
        paddingLeft: 5
    },
    icon: {
        position: 'relative',
        bottom: 1,
        marginLeft: 4,
    },
    noChangeText: {
        color: theme.colors[2],
        display: 'flex',
        margin: 0,
        fontSize: 16,
        fontWeight: 900,
    },
}))

const PercentChangeText = ({ score, className, iconWeight, iconClassName, passedStyle }) => {
    const theme = useTheme()
    const classes = useStyles()
    const unchanged = score !== null && score === 0
    const trend = score && score >= 0 ? 'up' : 'down'

    return (
        <Box
            component="span"
            alignItems="center"
            className={classnames(classes.wrap, className)}
            style={{ ...passedStyle, color: colors[trend][theme.palette.type] } || { color: colors[trend][theme.palette.type] }}
        >
            {unchanged ?
                <span className={classes.noChangeText}>--</span> :
                <>
                    {score} %
                    <FontAwesomeIcon icon={trend === 'up' ? faArrowUp : faArrowDown}
                        weight={iconWeight || 400}
                        className={classnames(classes.icon, iconClassName)}


                    />
                </>
            }
        </Box>
    )
}
PercentChangeText.propTypes = {
    score: PropTypes.number.isRequired,
    className: PropTypes.string,
    iconWeight: PropTypes.number,
    iconClassName: PropTypes.string,
    passedStyle: PropTypes.object

}
export default PercentChangeText