import React from 'react'
import PropTypes from 'prop-types'
import { Box, Grid, makeStyles, } from '@material-ui/core'
import StatusCard from './TrackersTabCards/StatusCard'
import CompetitorDomainsCard from './TrackersTabCards/CompetitorDomainsCard'
import KeyEmployeesCard from './TrackersTabCards/KeyEmployeesCard'
import SubjectLineKeywordsCard from './TrackersTabCards/SubjectLineKeywordsCard'
import { getSilencerSettings, getTrackSettings, updateSilencerSettings, addTrackSetting, removeTrackSetting } from '../../api/settings'
import { AuthenticationContext } from '../../App/AuthenticationContext'

export const DOMAIN_TYPE = 'domains'
export const EMPLOYEE_TYPE = 'employees'
export const KEYWORD_TYPE = 'keywords'

const useStyles = makeStyles((theme) => ({
    //This prop should be abstracted 
    summaryGrid: {
        flexGrow: 1,
    },

}))

const  useIsMountedRef = () => {  
    const isMountedRef = React.useRef(null)
    React.useEffect(() => {    
        isMountedRef.current = true
        return () => isMountedRef.current = false
    })
    
    return isMountedRef
}

const TrackersTab = ({ triggerToast }) => {
    const classes = useStyles()
    const [authState,] = React.useContext(AuthenticationContext)
    const [silencerSettings, setSilencerSettings] = React.useState({
        is_deleted: false,
        header_required: false,
    })

    const [domainTracks, setDomainTracks] = React.useState([])
    const [employeeTracks, setEmployeeTracks] = React.useState([])
    const [keywordTracks, setKeywordTracks] = React.useState([])
    const isMountedRef = useIsMountedRef()


    const initialGetSilencerSettings = () => {
        getSilencerSettings(authState).then(response => {
            if(isMountedRef.current){
                if (response.data.silencer_settings !== null) {
                    setSilencerSettings(response.data.silencer_settings)
                }
            }
        })
    }
    React.useEffect(initialGetSilencerSettings, [])

    const initialGetTrackSettings = () => {
        getTrackSettings(authState).then(response => {
            setDomainTracks(response.data.bi_domains.map(ob => ob.competitor_domains))
            setEmployeeTracks(response.data.employees_added.map(ob => ob.email))
            setKeywordTracks(response.data.keywords_added.map(ob => ob.keyword))
        })
    }
    React.useEffect(initialGetTrackSettings, [])

    const handleUpdateAndSaveSilencerSetting = (settingKey, settingValue) => {
        setSilencerSettings(
            {
                ...silencerSettings,
                [settingKey]: settingValue,
            }
        )
        updateSilencerSettings(settingKey, settingValue, authState).then(response => {
            if (response.status === 200 && triggerToast) {
                triggerToast()
            }
        })
    }

    //Wrapper so we don't have all these toast calls everywhere
    const callAddTrackSetting = (type, entry) => {
        addTrackSetting(type, entry, authState).then(response => {
            if (response.status === 200 && triggerToast) {
                triggerToast()
            }
        })
    }
    //Wrapper so we don't have all these toast calls everywhere
    const callRemoveTrackSetting = (type, entry) => {
        removeTrackSetting(type, entry, authState).then(response => {
            if (response.status === 200 && triggerToast) {
                triggerToast()
            }
        })
    }

    const handleAddTrackSettingEntry = (type, newEntry) => {
        if (type === DOMAIN_TYPE) {
            setDomainTracks([...domainTracks, newEntry])
            callAddTrackSetting(DOMAIN_TYPE, newEntry)
        }
        if (type === EMPLOYEE_TYPE) {
            setEmployeeTracks([...employeeTracks, newEntry])
            callAddTrackSetting(EMPLOYEE_TYPE, newEntry)
        }
        if (type === KEYWORD_TYPE) {
            setKeywordTracks([...keywordTracks, newEntry])
            callAddTrackSetting(KEYWORD_TYPE, newEntry)
        }
    }

    const handleRemoveTrackSettingEntry = (type, toRemove) => {
        if (type === DOMAIN_TYPE) {
            setDomainTracks(domainTracks.filter(item => item !== toRemove))
            callRemoveTrackSetting(DOMAIN_TYPE, toRemove)
        }
        if (type === EMPLOYEE_TYPE) {
            setEmployeeTracks(employeeTracks.filter(item => item !== toRemove))
            callRemoveTrackSetting(EMPLOYEE_TYPE, toRemove)
        }
        if (type === KEYWORD_TYPE) {
            setKeywordTracks(keywordTracks.filter(item => item !== toRemove))
            callRemoveTrackSetting(KEYWORD_TYPE, toRemove)
        }
    }


    return (
        <Box className={classes.summaryGrid}>
            <Grid container spacing={3}>
            {!authState.isMimeOSCustomer && <Grid item xs={12}>
                    <StatusCard
                        updateAndSaveSetting={handleUpdateAndSaveSilencerSetting}
                        silencerEnabled={silencerSettings.is_deleted}
                        headerRequired={silencerSettings.header_required}
                    />
                </Grid>}
                <Grid item xs={12}>
                    <CompetitorDomainsCard
                        addTag={handleAddTrackSettingEntry}
                        removeTag={handleRemoveTrackSettingEntry}
                        domains={domainTracks}
                    />
                </Grid>
                <Grid item xs={12}>
                    <KeyEmployeesCard
                        addTag={handleAddTrackSettingEntry}
                        removeTag={handleRemoveTrackSettingEntry}
                        employees={employeeTracks}
                    />
                </Grid>
                <Grid item xs={12}>
                    <SubjectLineKeywordsCard
                        addTag={handleAddTrackSettingEntry}
                        removeTag={handleRemoveTrackSettingEntry}
                        keywords={keywordTracks}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}
TrackersTab.propTypes = {
    triggerToast: PropTypes.func,
}
export default TrackersTab