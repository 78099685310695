import React from 'react'
import PropTypes from 'prop-types'
import SettingsCard from '@mc/common/components/Cards/SettingsCard'
import TagInput from '@mc/common/components/Input/TagInput'
import { KEYWORD_TYPE } from '../TrackersTab'

const SubjectLineKeywordsCard = ({
    addTag,
    removeTag,
    keywords,
}) => {

    const handleDeleteKeyword = (keyword) => {
        if (removeTag) {
            removeTag(KEYWORD_TYPE, keyword)
        }
    }

    const handleAddKeyword = (keyword) => {
        if (addTag) {
            addTag(KEYWORD_TYPE, keyword)
        }
    }

    return (
        <SettingsCard
            headerText={'Subject Line Keywords'}
            regularDescriptionText={'Add up to 10 keywords to track on the Trackers dashboard. Tracking for these items begins as soon as you add them here. You can input comma separated values too like special offer click here, free gift.'}
        >
            <TagInput
                id='subject_line_keywords'
                placeholder={'e.g. special offer click here, free gift'}
                tags={keywords}
                addTag={handleAddKeyword}
                deleteTag={handleDeleteKeyword}

            />
        </SettingsCard>
    )
}
SubjectLineKeywordsCard.propTypes = {
    addTag: PropTypes.func,
    removeTag: PropTypes.func,
    keywords: PropTypes.array,
}

export default SubjectLineKeywordsCard
