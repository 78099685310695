import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from 'gatsby'
import { makeStyles, Typography, Card, Divider, Box } from '@material-ui/core'
import PercentChangeText from '../Text/PercentChangeText';
import { textToIDFormat } from "../../lib/strings";

const useStyles = makeStyles((theme) => ({
    card: {
        paddingTop: 20,
        paddingBottom: 20,
        borderRadius: 6,
    },
    cardText:
    {
        paddingLeft: 20,

    },
    cardSectionText: {
        paddingTop: 12,
        paddingBottom: 12,
        display: 'inline-block',

    },
    statistic: {
        paddingTop: 8,
        paddingBottom: 8,
        paddingRight: 20
    },
    linkText: {
        float: 'right',
        paddingRight: 20,
        paddingTop: 18,
        textDecoration: 'none',
        color: theme.palette.primary.main
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between'
    }
}))


const RowDataCard = ({ headerText, subText, rows, infoLink }) => {
    const classes = useStyles();
    const [trackerInfoCardId] = React.useState(textToIDFormat(headerText,'card'),[headerText]);
    //TODO: get red and green into the theme
    return (
        <Card id={ trackerInfoCardId } className={classes.card}>
            <Typography variant="h2" gutterBottom className={classes.cardText}>
                {headerText}
            </Typography>
            {subText &&
                <Typography style={{ paddingBottom: 20 }} color="textPrimary" variant="body2" className={classes.cardText}>
                    {subText}
                </Typography>
            }
            <Divider />
            {rows &&
                <Box style={{ display: 'block' }}>
                    {rows.map((rowItem, index) => (
                        //TODO: should be a box
                        <div data-row={ textToIDFormat(rowItem.text) } key={index}>
                            <div key={index} className={classes.row}>
                                <Typography color="textPrimary" variant="body2" className={classnames(classes.cardText, classes.cardSectionText)}>
                                    {rowItem.text}
                                </Typography>
                                <Box style={{ display: 'flex' }} className={classes.statistic}>
                                    <Typography color="textPrimary" variant="h3" >
                                        {rowItem.statistic}
                                    </Typography>
                                    {(rowItem.change || rowItem.change === 0) &&
                                        <PercentChangeText score={rowItem.change} />
                                    }
                                </Box>
                            </div>
                            <Divider />
                        </div>
                    ))}
                </Box>
            }
            {infoLink &&
                <Link to={infoLink.to} className={classes.linkText}>
                    {infoLink.linkText}
                </Link>
            }
        </Card >
    )
}
RowDataCard.propTypes = {
    headerText: PropTypes.string.isRequired,
    subText: PropTypes.string,
    rows: PropTypes.array,
    infoLink: PropTypes.object,
}

export default RowDataCard
