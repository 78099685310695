import React from 'react'
import PropTypes from 'prop-types'
import {
    Accordion,
    AccordionSummary,
    FormControlLabel,
    Checkbox,
    AccordionDetails,
    Box,
    Button,
    makeStyles,
    Typography
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import SettingsCard from '@mc/common/components/Cards/SettingsCard'
import { 
    DEFAULT_GRAPH_RULE_PREFERENCES, 
    GraphRulePreferencesContext, 
    GraphRulePrefsAction } from './GraphRulePreferencesContext'


const subText = `You can modify the message used for a specific warning. This is a plain text message, HTML formatting is not supported. Placeholder text shows the default value. To remove a custom warning select Reset To Default and hit Save.
The single recipient message is an optional variation used when the message is only addressed to a single user. It should generally be the same as the base message.
This is an advanced configuration option unnecessary in most cases. If you have any questions about how to use this feature please contact us before making changes.`

const useStyles = makeStyles(theme => ({
    textArea: {
        resize: 'none',
        minWidth: 350,
        width: '100%',
        height: 54,
        borderRadius: 6,
        border: `1px solid ${theme.colors[2]}`,
        padding: 5,
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.body2.fontSize,
        lineHeight: theme.typography.body2.lineHeight,
    },
    textAreaBox: {
        width: '100%',
        padding: 10,
    },
    textAreaLabel: {
        paddingBottom: 10,
    },
    button: {
        height: 32,
        borderRadius: 6
    },
    buttonBox: {
        padding: '10px 0px'
    }
}))

const InlineEmailAlertsCard = ({
    saveGraphRulePreference,
}) => {

    const classes = useStyles()
    const [graphRulePrefsState, dispatchGraphRulePrefs] = React.useContext(GraphRulePreferencesContext)

    /**
     * Updates enabled/disabled flag for the rule
     * @param {string} ruleName name of the rule to update 'enabled'
     * @param {string} value  new value
     */
    const handleRuleToggle = (ruleName, value) => {
        dispatchGraphRulePrefs({type: GraphRulePrefsAction.ENABLE_DISABLE, 
                                payload: {
                                    ruleName: ruleName,
                                    value: value
                                }})
        saveGraphRulePreference(ruleName, 'enabled', value);
    }

    /**
     * Updates the 'rule_message' in the GraphRulePreferencesContext for the given ruleName
     * @param {string} ruleName name of the rule to update 'rule_message' for
     * @param {string} value new value
     */
    const handleUpdateRuleMessage = (ruleName, value) => {
        dispatchGraphRulePrefs({type: GraphRulePrefsAction.RULE_MESSAGE, 
            payload: {
                ruleName: ruleName,
                value: value
            }})
    }
    /**
     * Updates the 'rule_message_single' in the GraphRulePreferencesContext for the given ruleName
     * @param {string} ruleName name of the rule to update 'rule_message_single' for
     * @param {string} value new value
     */
    const handleUpdateRuleMessageSingle = (ruleName, value) => {
        dispatchGraphRulePrefs({type: GraphRulePrefsAction.RULE_MESSAGE_SINGLE, 
            payload: {
                ruleName: ruleName,
                value: value
            }})
    }
    
    /**
     * Saves both 'rule_message' and 'rule_message_single' in the GraphRulePreferencesContext for the given ruleName
     * @param {string} ruleName name of the rule to update 'rule_message_single'
     */
    const handleSaveRuleMessagesClicked = (ruleName) => {
        let ruleMessageTextString = graphRulePrefsState[ruleName]['rule_message'] || ''
        let ruleMessageSingleTextString = graphRulePrefsState[ruleName]['rule_message_single'] || ''
        saveGraphRulePreference(ruleName, 'rule_message', ruleMessageTextString);
        saveGraphRulePreference(ruleName, 'rule_message_single', ruleMessageSingleTextString);
    }

    /**
     * Resets both 'rule_message' and 'rule_message_single' back to their defaults for the given rule
     * @param {string} ruleName name of the rule to update
     */
    const handleResetToDefaultClicked = (ruleName) => {
        dispatchGraphRulePrefs({type: GraphRulePrefsAction.RULE_MESSAGE_DEFAULTS, 
            payload: {
                ruleName: ruleName,
            }})
            saveGraphRulePreference(ruleName, 'rule_message', DEFAULT_GRAPH_RULE_PREFERENCES[ruleName].rule_message);
            saveGraphRulePreference(ruleName, 'rule_message_single', DEFAULT_GRAPH_RULE_PREFERENCES[ruleName].rule_message_single);
    }

    return (
        <SettingsCard
            headerText={'Inline Dynamic Banners'}
            boldDescriptionText={'Set which tools you would like to enable and disable for inline dynamic banners.'}
            regularDescriptionText={subText}
        >
            {Object.entries(graphRulePrefsState).map(([ruleName, preferences]) => {
                if(!preferences.global) {
                    const isEnabled = preferences.enabled === 'true';
                    return (
                        <Accordion key={ruleName}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-label="Expand"
                                onClick={(event) => event.stopPropagation()}
                                onFocus={(event) => event.stopPropagation()}
                                id={ruleName}
                            >
                                <FormControlLabel
                                    aria-label="Enable"
                                    id={'ck_label_' + ruleName}
                                    control={
                                        <Checkbox color='primary'
                                                  checked={isEnabled}
                                                  onClick={() => handleRuleToggle(ruleName, (!isEnabled).toString())}
                                        />}
                                    label={ruleName}
                                />
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box style={{width: '100%'}}>
                                    <Box style={{display: 'flex'}}>
                                        <Box className={classes.textAreaBox}>
                                            <Typography className={classes.textAreaLabel} color="textPrimary"
                                                        variant="body2">Base Message</Typography>
                                            <textarea
                                                id={'input_message_' + ruleName}
                                                value={preferences.rule_message || ''}
                                                className={classes.textArea}
                                                onChange={(event) => handleUpdateRuleMessage(ruleName, event.target.value)}
                                            />
                                        </Box>
                                        <Box className={classes.textAreaBox}>
                                            <Typography className={classes.textAreaLabel} color="textPrimary"
                                                        variant="body2">Single Recipient</Typography>
                                            <textarea
                                                id={'input_single_' + ruleName}
                                                value={preferences.rule_message_single || ''}
                                                className={classes.textArea}
                                                onChange={(event) => handleUpdateRuleMessageSingle(ruleName, event.target.value)}
                                            />
                                        </Box>
                                    </Box>
                                    <Box className={classes.buttonBox}>
                                        <Button id={'btn_save_' + ruleName} className={classes.button}
                                                variant="contained" color="primary"
                                                onClick={() => handleSaveRuleMessagesClicked(ruleName)}
                                        >
                                            Save
                                        </Button>
                                        <Button id={'btn_reset_' + ruleName} className={classes.button}
                                                style={{marginLeft: 10}} color="secondary" variant="contained"
                                                onClick={() => handleResetToDefaultClicked(ruleName)}
                                        >
                                            Reset to Default
                                        </Button>
                                    </Box>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    )
                }
            })}

        </SettingsCard>
    )
}

InlineEmailAlertsCard.propTypes = {
    saveGraphRulePreference: PropTypes.func,
}

export default InlineEmailAlertsCard
