import React from 'react'
import PropTypes from 'prop-types'
import {
    FormControl,
    FormControlLabel,
    Typography,
    Radio,
    RadioGroup,

} from '@material-ui/core'
import SettingsCard from '@mc/common/components/Cards/SettingsCard'
import { AuthenticationContext } from '../../../App/AuthenticationContext'
import { RULE_NAMES } from '@mc/admin/src/utils/codebreakerData'

const RuleCustomizationsCard = ({
    fuzzyNameMatchPrecision = 'medium',
    fuzzyNameMatchCount = 2,
    updateAndSaveGraphRuleCustomization,
}) => {

    const [authState,] = React.useContext(AuthenticationContext)

    const handleUpdateAndSaveGraphRuleCustomization = (preferenceName, preferenceKey, preferenceValue) => {
        if (updateAndSaveGraphRuleCustomization) {
            updateAndSaveGraphRuleCustomization(preferenceName, preferenceKey, preferenceValue)
        }
    }

    const handleFuzzySettingChange = (preferenceValue) => {
        handleUpdateAndSaveGraphRuleCustomization(RULE_NAMES.SimilarNameDifferentEmailRule, 'precision', preferenceValue);
        handleUpdateAndSaveGraphRuleCustomization(RULE_NAMES.SimilarNameWithinCompanyRule, 'precision', preferenceValue);
        // Must be last so fuzzyNameMatchPrecision updates the UI radio button ???
        handleUpdateAndSaveGraphRuleCustomization('FuzzyMatching', 'precision', preferenceValue);
    }

    return (
        <SettingsCard
            headerText={'Name Matching Configuration'}
        >
            <Typography style={{ fontWeight: 600, paddingTop: 10 }} color="textPrimary" variant="body2">
                Precision of CyberGraph Dynamic Banner fuzzy name matching
            </Typography>
            <Typography color="textPrimary" variant="body2">
                Lower precisions require fewer matching characters or sounds.
            </Typography>
            <FormControl component="fieldset">
                <RadioGroup row value={fuzzyNameMatchPrecision}
                    onChange={(elem) => handleFuzzySettingChange(elem.target.value)}>
                    <FormControlLabel id='radio_name_matching_exact' value="exact" control={<Radio color="primary" />} label={
                        <>
                            <Typography style={{ fontWeight: 600 }} color="textPrimary" variant="body2">
                                Exact
                            </Typography>
                        </>
                    } />
                    <FormControlLabel id='radio_name_matching_high' value="high" control={<Radio color="primary" />} label={
                        <>
                            <Typography style={{ fontWeight: 600 }} color="textPrimary" variant="body2">
                                High
                            </Typography>
                        </>
                    } />
                    <FormControlLabel id='radio_name_matching_medium' value="medium" control={<Radio color="primary" />} label={
                        <>
                            <Typography style={{ fontWeight: 600 }} color="textPrimary" variant="body2">
                                Medium
                            </Typography>
                        </>
                    } />
                    <FormControlLabel id='radio_name_matching_low' value="low" control={<Radio color="primary" />} label={
                        <>
                            <Typography style={{ fontWeight: 600 }} color="textPrimary" variant="body2">
                                Low
                            </Typography>
                        </>
                    } />
                </RadioGroup>
            </FormControl>
            {!authState.isMimeOSCustomer &&  <div>
                <Typography style={{ fontWeight: 600, paddingTop: 10 }} color="textPrimary" variant="body2">
                    The number of fuzzy matched names required to trigger Gatekeeper
                </Typography>
                <FormControl component="fieldset">
                    <RadioGroup row value={fuzzyNameMatchCount}
                        onChange={(elem) => handleUpdateAndSaveGraphRuleCustomization('MisaddressedRecipientRule', 'match_count', elem.target.value)}>
                        <FormControlLabel value={1} control={<Radio color="primary" />} label={
                            <>
                                <Typography style={{ fontWeight: 600 }} color="textPrimary" variant="body2">
                                    1
                                </Typography>
                            </>
                        } />
                        <FormControlLabel value={2} control={<Radio color="primary" />} label={
                            <>
                                <Typography style={{ fontWeight: 600 }} color="textPrimary" variant="body2">
                                    2
                                </Typography>
                            </>
                        } />
                    </RadioGroup>
                </FormControl>
            </div>}
        </SettingsCard>
    )
}
RuleCustomizationsCard.propTypes = {
    fuzzyNameMatchPrecision: PropTypes.string,
    fuzzyNameMatchCount: PropTypes.number,
    updateAndSaveGraphRuleCustomization: PropTypes.func,
}

export default RuleCustomizationsCard
