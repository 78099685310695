import React from 'react'
import PropTypes from 'prop-types'
import {
    Box,
    Chip,
    Typography,
    Button,
    makeStyles,
} from '@material-ui/core'
import CommonInput from './CommonInput'

const useStyles = makeStyles((theme) => ({
    //This prop should be abstracted 
    errorMessageText: {
        color: theme.rainbowColors.red
    }

}))

const TagInput = ({id, deleteTag, validateInput, addTag, invalidInputMessage, tags, placeholder,}) => {
    const classes = useStyles()
    const [displayedTags, setDisplayedTags] = React.useState([])
    const [textContent, setTextContent] = React.useState('')
    const [errorText, setErrorText] = React.useState(null)

    const handleDelete = (toDelete) => {
        if (deleteTag) {
            deleteTag(toDelete)
        }
        else {
            const filteredItems = displayedTags.filter(item => item !== toDelete)
            setDisplayedTags(filteredItems)
        }
    }
    //TODO: no add duplicates?
    const handleAdd = () => {
        setErrorText(null)
        let inputValid = true
        if (validateInput) {
            inputValid = validateInput(textContent)
        }
        if (inputValid) {
            if (addTag) {
                addTag(textContent)
            }
            else {
                setDisplayedTags([...displayedTags, textContent])
            }
            setTextContent('')
        }
        else if (invalidInputMessage) {
            setErrorText(invalidInputMessage)
        }


    }
    const shownTags = tags || displayedTags

    return (
        <>
            {errorText && <Typography className={classes.errorMessageText} variant='body2'>{errorText}</Typography>}
            <Box display='flex'>
                <CommonInput
                    id={'input_' + id}
                    value={textContent}
                    placeholder={placeholder}
                    onEnter={handleAdd}
                    onChange={setTextContent}/>
                <Button id={'btn_add_' + id } style={{marginLeft: 10, textTransform: 'none', padding: '6px 12px'}}
                        color="primary" variant="contained" onClick={handleAdd}>Add</Button>
            </Box>
            <Box>
                {shownTags.map((tag, index) => (
                    <Chip id={'chip_' + id + '_' + index} key={index} style={{margin: 5}} color="secondary" size="small"
                          onDelete={() => handleDelete(tag)} label={tag}/>
                ))
                }
            </Box>
        </>
    )


}
TagInput.propTypes = {
    id: PropTypes.string,
    deleteTag: PropTypes.func,
    validateInput: PropTypes.func,
    addTag: PropTypes.func,
    invalidInputMessage: PropTypes.string,
    tags: PropTypes.array,
    placeholder: PropTypes.string,
}

export default TagInput
