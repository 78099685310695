export const DEFAULT_EMAIL_ALERT_SETTINGS = {
    account_type: null,
    ai_assist: 'true',
    cloud_graph_alert_type: "image",
    codebreaker_credit_text: "Powered by Mimecast",
    codebreaker_image_report_safe_text: "Report or Mark Safe",
    codebreaker_report_alert_address: "",
    codebreaker_report_text: "Report or Mark Safe",
    codebreaker_safe_text: "Mark as Safe",
    enable_graph_rule_alerts: 'false',
    header_required: false,
    server_type: "cloud",
}

export const DEFAULT_GRAPH_CUSTOMIZATIONS = {
    FuzzyMatching: {
        precision: 'medium',
    },
    MisaddressedRecipientRule: {
        match_count: '1',
    },
}
