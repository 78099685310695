import React from 'react'
import PropTypes from 'prop-types'
import {
    FormControl,
    FormControlLabel,
    Typography,
    Radio,
    RadioGroup,
    makeStyles,
    Box,
    Button
} from '@material-ui/core'
import SettingsCard from '@mc/common/components/Cards/SettingsCard'
import CommonInput from '@mc/common/components/Input/CommonInput'


const useStyles = makeStyles(theme => ({
    inputField: {
        maxWidth: 400
    },
    inputLabelText: {
        fontWeight: 600,
        paddingBottom: 10
    },
    textFieldBox: {
        paddingTop: 20,
    },
    button: {
        height: 32,
        borderRadius: 6
    },
    buttonBox: {
        padding: '10px 0px'
    }
}))

const DEFAULT_IMAGE_REPORT_BUTTON_TEXT = 'Report or Mark Safe'
const DEFAULT_TEXT_REPORT_BUTTON_TEXT = 'Report'
const DEFAULT_TEXT_MARK_SAFE_BUTTON_TEXT = 'Mark Safe'
const DEFAULT_CREDIT_TEXT = 'Powered by Mimecast'

const WarningsCard = ({
    updateSetting,
    saveSetting,
    updateAndSaveSetting,
    aiSuppressionStatus,
    warningType = 'image',
    imageReportButtonText = DEFAULT_IMAGE_REPORT_BUTTON_TEXT,
    textReportButtonText = DEFAULT_TEXT_REPORT_BUTTON_TEXT,
    textMarkSafeButtonText = DEFAULT_TEXT_MARK_SAFE_BUTTON_TEXT,
    creditText = DEFAULT_CREDIT_TEXT,
}) => {

    const classes = useStyles()

    const handleUpdate = (settingKey, settingValue) => {
        if (updateSetting) {
            updateSetting(settingKey, settingValue)
        }
    }
    const handleSave = (settingKey, settingValue) => {
        if (saveSetting) {
            saveSetting(settingKey, settingValue)
        }
    }
    const handleUpdateAndSaveSetting = (settingKey, settingValue) => {
        if (updateAndSaveSetting) {
            updateAndSaveSetting(settingKey, settingValue)
        }
    }

    return (

        < SettingsCard
            headerText={'Banner Configurations'}
        >
            <FormControl component="fieldset">
                <RadioGroup value={warningType}
                    onChange={(elem) => handleUpdateAndSaveSetting('cloud_graph_alert_type', elem.target.value)}>
                    <FormControlLabel id='radio_btn_text_banners' value="text" control={<Radio color="primary" />} label={
                        <>
                            <Typography style={{ fontWeight: 600 }} color="textPrimary" variant="body2">
                                Text Banners
                            </Typography>
                        </>
                    } />
                    <FormControlLabel id='radio_btn_image_banners' value="image" control={<Radio color="primary" />} label={
                        <>
                            <Typography style={{ fontWeight: 600 }} color="textPrimary" variant="body2">
                                Image Banners
                            </Typography>
                        </>
                    } />
                </RadioGroup>
            </FormControl>
            { warningType === 'image' ?
                <Box className={classes.textFieldBox}>
                    <Typography className={classes.inputLabelText} color="textPrimary" variant="body2">
                        Dynamic Banner Mark Safe or Report Text
                    </Typography>
                    <CommonInput
                        id='input_report_image_text'
                        value={imageReportButtonText}
                        className={classes.inputField} placeholder="Dynamic Banner Mark Safe or Report Text"
                        onChange={(value) => handleUpdate('codebreaker_image_report_safe_text', value)} />
                    <Box className={classes.buttonBox}>
                        <Button  id='btn_save_report_image_text' className={classes.button} variant="contained" color="primary"
                            onClick={() => handleSave('codebreaker_image_report_safe_text', imageReportButtonText)}>
                            Save
                        </Button>
                        <Button id='btn_reset_report_image_text' className={classes.button} style={{ marginLeft: 10 }} color="secondary" variant="contained"
                            onClick={() => handleUpdateAndSaveSetting('codebreaker_image_report_safe_text', DEFAULT_IMAGE_REPORT_BUTTON_TEXT)}
                        >
                            Reset to Default
                        </Button>
                    </Box>
                </Box>
                :
                <>
                    <Box className={classes.textFieldBox}>
                        <Typography className={classes.inputLabelText} color="textPrimary" variant="body2">
                            Banner Mark Safe Text
                        </Typography>
                        <CommonInput
                            id='input_mark_safe_banner_text'
                            value={textMarkSafeButtonText}
                            className={classes.inputField}
                            placeholder="Dynamic Banner Mark Safe Text"
                            onChange={(value) => handleUpdate('codebreaker_safe_text', value)} />
                        <Box className={classes.buttonBox}>
                            <Button id='btn_save_mark_safe_banner_text' className={classes.button} variant="contained" color="primary"
                                onClick={() => handleSave('codebreaker_safe_text', textMarkSafeButtonText)}
                            >
                                Save
                            </Button>
                            <Button id='btn_reset_mark_safe_banner_text' className={classes.button} style={{ marginLeft: 10 }} color="secondary" variant="contained"
                                onClick={() => handleUpdateAndSaveSetting('codebreaker_safe_text', DEFAULT_TEXT_MARK_SAFE_BUTTON_TEXT)}
                            >
                                Reset to Default
                            </Button>
                        </Box>
                    </Box>
                    
                    <Box className={classes.textFieldBox}>
                        <Typography className={classes.inputLabelText} color="textPrimary" variant="body2">
                            Banner Report Text
                        </Typography>
                        <CommonInput
                            id='input_report_banner_text'
                            value={textReportButtonText}
                            className={classes.inputField} placeholder="Dynamic Banner Report Text"
                            onChange={(value) => handleUpdate('codebreaker_report_text', value)} />
                        <Box className={classes.buttonBox}>
                            <Button id='btn_save_report_banner_text' className={classes.button} variant="contained" color="primary"
                                onClick={() => handleSave('codebreaker_report_text', textReportButtonText)}
                            >
                                Save
                            </Button>
                            <Button id='btn_reset_report_banner_text' className={classes.button} style={{ marginLeft: 10 }} color="secondary" variant="contained"
                                onClick={() => handleUpdateAndSaveSetting('codebreaker_report_text', DEFAULT_TEXT_REPORT_BUTTON_TEXT)}
                            >
                                Reset to Default
                            </Button>
                        </Box>
                    </Box>
                </>
            }

            <Box className={classes.textFieldBox}>
                <Typography className={classes.inputLabelText} color="textPrimary" variant="body2">
                    Banner Credit Text
                </Typography>
                <CommonInput value={creditText}
                    id='input_credit_text'
                    className={classes.inputField} placeholder="Credit Text"
                    onChange={(value) => handleUpdate('codebreaker_credit_text', value)} />
                <Box className={classes.buttonBox}>
                    <Button id='btn_save_credit_text' className={classes.button} variant="contained" color="primary"
                        onClick={() => handleSave('codebreaker_credit_text', creditText)}
                    >
                        Save
                    </Button>
                    <Button id='btn_reset_credit_text' className={classes.button} style={{ marginLeft: 10 }} color="secondary" variant="contained"
                        onClick={() => handleUpdateAndSaveSetting('codebreaker_credit_text', DEFAULT_CREDIT_TEXT)}
                    >
                        Reset to Default
                    </Button>
                </Box>
            </Box>
            <FormControl component="fieldset">
            <Typography style={{ fontWeight: 600, paddingTop: 20 }} color="textPrimary" variant="body2">
                Quantity of Banners
            </Typography>
                <RadioGroup value={aiSuppressionStatus} onChange={(elem) => handleUpdateAndSaveSetting('ai_assist', elem.target.value)}>
                    <FormControlLabel id='radio_targeted' value="true" control={<Radio color="primary" />} label={
                        <>
                            <Typography style={{ fontWeight: 600 }} color="textPrimary" variant="body2">
                                Targeted (Banners only on more probable threats)
                            </Typography>
                        </>
                    } />
                    <FormControlLabel id='radio_board' value="false" control={<Radio color="primary" />} label={
                        <>
                            <Typography style={{ fontWeight: 600 }} color="textPrimary" variant="body2">
                                Broad (Banners on all possible threats)
                            </Typography>
                        </>
                    } />
                </RadioGroup>
            </FormControl>
        </SettingsCard >
    )
}
WarningsCard.propTypes = {
    updateSetting: PropTypes.func,
    saveSetting: PropTypes.func,
    updateAndSaveSetting: PropTypes.func,
    aiSuppressionStatus: PropTypes.bool,
    warningType: PropTypes.string,
    imageReportButtonText: PropTypes.string,
    textReportButtonText: PropTypes.string,
    textMarkSafeButtonText: PropTypes.string,
    creditText: PropTypes.string,
}
export default WarningsCard
