import React from 'react'
import PropTypes from 'prop-types'
import SettingsCard from '@mc/common/components/Cards/SettingsCard'
import TagInput from '@mc/common/components/Input/TagInput'
import { DOMAIN_TYPE } from '../TrackersTab'
import { validateDomain } from '@mc/common/lib/strings'


const CompetitorDomainsCard = ({
    addTag,
    removeTag,
    domains,
}) => {


    const handleDeleteDomain = (domain) => {
        if (removeTag) {
            removeTag(DOMAIN_TYPE, domain)
        }
    }

    const handleAddDomain = (domain) => {
        let domainInput = domain.trim().toLowerCase()
        if (addTag) {
            addTag(DOMAIN_TYPE, domainInput)
        }

    }

    return (
        <SettingsCard
            headerText={'Competitor Domains'}
            regularDescriptionText={'Add up to 10 competitor domains to track on the Trackers dashboard. Tracking for these items begins as soon as you add them here. You can input comma separated values too like abc.com, janedoe.com.'}
        >
            <TagInput
                id='competitor_domains'
                placeholder={'e.g. abc.com, janedoe.com'}
                tags={domains}
                addTag={handleAddDomain}
                deleteTag={handleDeleteDomain}
                invalidInputMessage={'Not a valid domain.'}
                validateInput={validateDomain}

            />
        </SettingsCard>
    )
}
CompetitorDomainsCard.propTypes = {
    addTag: PropTypes.func,
    removeTag: PropTypes.func,
    domains: PropTypes.array,
}
export default CompetitorDomainsCard
