import React from 'react'
import PropTypes from 'prop-types'
import {
    FormControl,
    FormControlLabel,
    Typography,
    Radio,
    RadioGroup,
    FormGroup,
    Switch
} from '@material-ui/core'
import SettingsCard from '@mc/common/components/Cards/SettingsCard'

const StatusCard = ({
    updateAndSaveSetting,
    silencerEnabled = 'true',
    headerRequired = 'false',
}) => {

    const handleSaveAndUpdateSetting = (settingKey, settingValue) => {
        if (updateAndSaveSetting) {
            updateAndSaveSetting(settingKey, settingValue)
        }
    }

    const getBooleanHeaderReq = () => {return headerRequired === 'true'}
    return (
        <SettingsCard
            headerText={'Status'}
        >
            <FormControl component="fieldset">
                <RadioGroup value={silencerEnabled} onChange={(elem) => handleSaveAndUpdateSetting('is_deleted', elem.target.value)}>
                    <FormControlLabel value='false' control={<Radio color="primary" />} label={
                        <>
                            <Typography style={{ fontWeight: 600 }} color="textPrimary" variant="body2">
                                Enabled
                            </Typography>
                        </>
                    } />
                    <FormControlLabel value='true' control={<Radio color="primary" />} label={
                        <>
                            <Typography style={{ fontWeight: 600 }} color="textPrimary" variant="body2">
                                Disabled
                            </Typography>
                        </>
                    } />
                </RadioGroup>
            </FormControl>
            <Typography style={{ fontWeight: 600, paddingTop: 10 }} color="textPrimary" variant="body2">
                Require X-Redata-Silencer-Enabled header
            </Typography>
            <Typography color="textPrimary" variant="body2">
                When selected this header must be present with a value of true or CyberGraph Trackers will not fire when enabled.
            </Typography>
            <FormGroup>
                <FormControlLabel
                    control={<Switch color="primary" checked={getBooleanHeaderReq()} onChange={() => handleSaveAndUpdateSetting('header_required', (!getBooleanHeaderReq()).toString())} />}
                    label={getBooleanHeaderReq() ? 'Enabled' : 'Disabled'}
                />
            </FormGroup>
        </SettingsCard>
    )
}
StatusCard.propTypes = {
    updateAndSaveSetting: PropTypes.func,
    silencerEnabled: PropTypes.bool,
    headerRequired: PropTypes.bool,
}

export default StatusCard