/**
 * Displays customer info purely for informational purposes.
 */
import React from 'react'
import { Box, } from '@material-ui/core'
import { AuthenticationContext } from '../../App/AuthenticationContext'
import RowDataCard from '@mc/common/components/Cards/RowDataCard'

/**
 * Translate server_type to something more user-friendly
 */
export const SERVER_TYPE_UX = Object.freeze({
    'cloud': 'MessageControl Legacy',
    'mimeos': 'CyberGraph SEG',
    'on_prem': 'MessageControl Legacy On-premise',
});

const InfoTab = () => {
    const [authState,] = React.useContext(AuthenticationContext)

    const rows = [
        {
            text: 'Customer ID',
            statistic: authState.customerInfo.customer_id || '',
        },
        {
            text: 'Mimecast Account Code',
            statistic: authState.customerInfo.global_customer_id || '',
        },
        {
            text: 'Account Type',
            statistic: SERVER_TYPE_UX[authState.customerInfo.server_type] || '',
        },
    ]

    return (
        <Box>
            <RowDataCard
                headerText='Customer Information'
                subText='Additional data about your organization'
                rows={rows}
            />
        </Box>
    )
}

InfoTab.propTypes = {}
export default InfoTab