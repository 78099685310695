import React from 'react'
import PropTypes from 'prop-types'
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid,
    makeStyles,
} from '@material-ui/core'
import SettingsCard from '@mc/common/components/Cards/SettingsCard'
import CommonInput from '@mc/common/components/Input/CommonInput'
import { getEmailDigest, updateEmailDigest, getCodebreakerSettings, updateCodebreakerSetting } from '@mc/admin/src/api/settings'
import { AuthenticationContext } from '../../App/AuthenticationContext'

const DAILY_DIGEST = 'daily_digest'
const WEEKLY_DIGEST = 'weekly_digest'
const ADMIN_DAILY_DIGEST = 'daily_admin_digest'
const ADMIN_WEEKLY_DIGEST = 'weekly_admin_digest'

const useStyles = makeStyles((theme) => ({
    //This prop should be abstracted 
    summaryGrid: {
        flexGrow: 1,
    },
    textField: {
        minWidth: 350
    },
    textFieldBox: {
        paddingTop: 20,
    },
    button: {
        height: 32,
        borderRadius: 6
    },
    buttonBox: {
        padding: '10px 0px'
    }

}))

const  useIsMountedRef = () => {  
    const isMountedRef = React.useRef(null)
    React.useEffect(() => {    
        isMountedRef.current = true
        return () => isMountedRef.current = false
    })
    
    return isMountedRef
}

const NotificationTab = ({ triggerToast }) => {
    const classes = useStyles()

    const [authState,] = React.useContext(AuthenticationContext)
    const [dailyDigest, setDailyDigest] = React.useState(1)
    const [weeklyDigest, setWeeklyDigest] = React.useState(1)
    const [adminDailyDigest, setAdminDailyDigest] = React.useState(1)
    const [adminWeeklyDigest, setAdminWeeklyDigest] = React.useState(1)
    const [reportAlertAddress, setReportAlertAddress] = React.useState('')
    const isMountedRef = useIsMountedRef()


    const initialGetEmailDigest = () => {
        getEmailDigest(authState).then(response => {
            if(isMountedRef.current){
                setDailyDigest(response.data.digest_preferences.daily_digest)
                setWeeklyDigest(response.data.digest_preferences.weekly_digest)
                setAdminDailyDigest(response.data.digest_preferences.daily_admin_digest)
                setAdminWeeklyDigest(response.data.digest_preferences.weekly_admin_digest)
            }
        })
    }

    React.useEffect(initialGetEmailDigest, [])

    const initialGetCodebreakerSettings = () => {
        getCodebreakerSettings(authState).then(response => {
            if(isMountedRef.current){
                setReportAlertAddress(response.data.codebreaker_settings.codebreaker_report_alert_address)
            }
        })
    }
    React.useEffect(initialGetCodebreakerSettings, [])

    //Wrapper so we don't have all these toast calls everywhere

    const callUpdateEmailDigest = (digest, newVal) => {
        updateEmailDigest(digest, newVal, authState).then(response => {
            if (response.status === 200 && triggerToast) {
                triggerToast()
            }
        })
    }

    const handleClickSaveReportAlertAddress = () => {
        updateCodebreakerSetting('codebreaker_report_alert_address', reportAlertAddress, authState).then(response => {
            if (response.status === 200 && triggerToast) {
                triggerToast()
            }
        })
    }


    const handleToggleDigestValue = (digest_name) => {

        //I don't know why we are using 1 and 0 instead of true false for the digest value
        if (digest_name === DAILY_DIGEST) {
            let newValue = dailyDigest === 1 ? 0 : 1
            setDailyDigest(newValue)
            callUpdateEmailDigest(DAILY_DIGEST, newValue)
        }
        if (digest_name === WEEKLY_DIGEST) {
            let newValue = weeklyDigest === 1 ? 0 : 1
            setWeeklyDigest(newValue)
            callUpdateEmailDigest(WEEKLY_DIGEST, newValue)
        }
        if (digest_name === ADMIN_DAILY_DIGEST) {
            let newValue = adminDailyDigest === 1 ? 0 : 1
            setAdminDailyDigest(newValue)
            callUpdateEmailDigest(ADMIN_DAILY_DIGEST, newValue)
        }
        if (digest_name === ADMIN_WEEKLY_DIGEST) {
            let newValue = adminWeeklyDigest === 1 ? 0 : 1
            setAdminWeeklyDigest(newValue)
            callUpdateEmailDigest(ADMIN_WEEKLY_DIGEST, newValue)
        }
    }

    return (
        <Box className={classes.summaryGrid}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <SettingsCard
                        headerText={'Tracker Summary Notification'}
                        regularDescriptionText={'Mimecast can email you a summary of tracking statistics for your organization on a weekly or daily basis.'}
                    >
                        <FormGroup>
                            <FormControlLabel
                                id='ck_label_daily'
                                control={
                                    <Checkbox
                                        size='small'
                                        checked={adminDailyDigest === 1}
                                        onChange={() => handleToggleDigestValue(ADMIN_DAILY_DIGEST)}
                                        name="daily"
                                        color="primary"
                                    />
                                }
                                label="Daily"
                            />
                            <FormControlLabel
                                id='ck_label_weekly'
                                control={
                                    <Checkbox
                                        size='small'
                                        checked={adminWeeklyDigest === 1}
                                        onChange={() => handleToggleDigestValue(ADMIN_WEEKLY_DIGEST)}
                                        name="weekly"
                                        color="primary"
                                    />
                                }
                                label="Weekly"
                            />
                        </FormGroup>
                    </SettingsCard>
                </Grid>
                <Grid item xs={12}>
                    <SettingsCard
                        headerText={'Reported Dangerous Notification'}
                        regularDescriptionText={'If you wish to receive an email notification whenever your users report an email as dangerous you can specify an address below.\
                        Only a single address can be used.'}
                    >
                        <CommonInput id='input_reported_dangerous' value={reportAlertAddress} placeHolder='jdoe@abc.com' style={{ maxWidth: 400 }} onChange={setReportAlertAddress} />
                        <Box className={classes.buttonBox}>
                            <Button id='btn_save_reported_dangerous' className={classes.button} variant="contained" color="primary" onClick={handleClickSaveReportAlertAddress}>
                                Save
                        </Button>
                        </Box>
                    </SettingsCard>
                </Grid>
            </Grid>
        </Box >
    )
}

NotificationTab.propTypes = {
    triggerToast: PropTypes.func,
}

export default NotificationTab
