import Axios from "axios";
import { getRegionalizedUrl, MANAGE_API } from "@mc/common/lib/url-utils"
import {
    encodeUrlParams
} from "@mc/common/lib/url-params"

export const getCodebreakerSettings = (authState) => {
    return Axios({
        method: 'post',
        url: getRegionalizedUrl(MANAGE_API) + '/codebreaker/settings/all',
        params: {
            v: 1,
            user_email: authState.email || '',
            session: authState.session || '',
        },
    })
}

export const updateCodebreakerSetting = (setting, value, authState) => {
    return Axios({
        method: 'post',
        url: getRegionalizedUrl(MANAGE_API) + '/codebreaker/settings',
        data: encodeUrlParams({
            setting: setting,
            value: value,
            v: 1,
            user_email: authState.email || '',
            session: authState.session || '',
        }),
        crossdomain: true,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })
}

export const getGraphRules = (authState) => {
    return Axios({
        method: 'post',
        url: getRegionalizedUrl(MANAGE_API) + '/graph_rule_preferences',
        data: encodeUrlParams({
            v: 1,
            user_email: authState.email || '',
            session: authState.session || '',
        }),
        crossdomain: true,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })
}

export const updateGraphRule = (ruleName, ruleField, ruleValue, authState) => {
    return Axios({
        method: 'post',
        url: getRegionalizedUrl(MANAGE_API) + '/graph_rule',
        data: encodeUrlParams({
            v: 1,
            user_email: authState.email || '',
            session: authState.session || '',
            rule_name: ruleName,
            rule_field: ruleField,
            rule_value: ruleValue,
        }),
        crossdomain: true,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })
}

export const getSilencerSettings = (authState) => {
    return Axios({
        method: 'post',
        url: getRegionalizedUrl(MANAGE_API) + '/get_silencer_settings',
        data: encodeUrlParams({
            v: 1,
            user_email: authState.email || '',
            session: authState.session || '',
        }),
        crossdomain: true,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })
}

export const updateSilencerSettings = (setting, value, authState) => {
    return Axios({
        method: 'post',
        url: getRegionalizedUrl(MANAGE_API) + '/update_silencer_settings',
        data: encodeUrlParams({
            v: 1,
            user_email: authState.email || '',
            session: authState.session || '',
            setting: setting,
            value: value
        }),
        crossdomain: true,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })
}

export const getTrackSettings = (authState) => {
    return Axios({
        method: 'post',
        url: getRegionalizedUrl(MANAGE_API) + '/get_track_settings',
        data: encodeUrlParams({
            v: 1,
            user_email: authState.email || '',
            session: authState.session || '',
        }),
        crossdomain: true,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })
}

export const addTrackSetting = (type, value, authState) => {
    return Axios({
        method: 'post',
        url: getRegionalizedUrl(MANAGE_API) + '/add_track_settings',
        data: encodeUrlParams({
            v: 1,
            user_email: authState.email || '',
            session: authState.session || '',
            value: value,
            type: type
        }),
        crossdomain: true,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })
}

export const removeTrackSetting = (type, value, authState) => {
    return Axios({
        method: 'post',
        url: getRegionalizedUrl(MANAGE_API) + '/remove_track_settings',
        data: encodeUrlParams({
            v: 1,
            user_email: authState.email || '',
            session: authState.session || '',
            value: value,
            type: type
        }),
        crossdomain: true,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })
}

export const updateEmailDigest = (digest_type, enable_disable, authState) => {
    return Axios({
        method: 'post',
        url: getRegionalizedUrl(MANAGE_API) + '/email_digest',
        data: encodeUrlParams({
            v: 1,
            user_email: authState.email || '',
            session: authState.session || '',
            [digest_type]: enable_disable,
        }),
        crossdomain: true,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })
}

export const getEmailDigest = (authState) => {
    return Axios({
        method: 'post',
        url: getRegionalizedUrl(MANAGE_API) + '/email_digest/all',
        data: encodeUrlParams({
            v: 1,
            user_email: authState.email || '',
            session: authState.session || '',
        }),
        crossdomain: true,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })
}

export const getSSOState = (authState) => {
    return Axios({
        method: 'post',
        url: getRegionalizedUrl(MANAGE_API) + '/settings/sso',
        data: encodeUrlParams({
            application: 'reporting',
            identifier: authState.customerInfo.customer_id
        }),
        crossdomain: true,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })
}

export const updateSSOState = (enabled, authState) => {
    return Axios({
        method: 'post',
        url: getRegionalizedUrl(MANAGE_API) + '/settings/sso/update',
        data: encodeUrlParams({
            user_email: authState.email || '',
            session: authState.session || '',
            application: 'reporting',
            identifier: authState.customerInfo.customer_id,
            enabled: enabled
        }),
        crossdomain: true,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })
}





