import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Typography, Card, } from '@material-ui/core'
const useStyles = makeStyles((theme) => ({
    card: {
        padding: 20,
        borderRadius: 6,
    },

}))

const SettingsCard = ({ headerText, boldDescriptionText, regularDescriptionText, children }) => {
    const classes = useStyles();
    return (
        <Card className={classes.card}>
            <Typography variant="h2" gutterBottom >
                {headerText}
            </Typography>
            {boldDescriptionText &&
                <Typography style={{ fontWeight: 600 }} color="textPrimary" variant="body2" >
                    {boldDescriptionText}
                </Typography>
            }
            {regularDescriptionText &&
                <Typography style={{ paddingBottom: 20 }} color="textPrimary" variant="body2" >
                    {regularDescriptionText}
                </Typography>
            }
            {children}
        </Card>
    )
}
SettingsCard.propTypes = {
    headerText: PropTypes.string.isRequired,
    boldDescriptionText: PropTypes.string,
    regularDescriptionText: PropTypes.string,
    children: PropTypes.node
}

export default SettingsCard