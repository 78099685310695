import React from 'react'
import PropTypes from 'prop-types'
import {
    FormControl,
    FormControlLabel,
    Typography,
    Radio,
    RadioGroup,

} from '@material-ui/core'
import SettingsCard from '@mc/common/components/Cards/SettingsCard'

const SSOCard = ({
    ssoEnabled = "false",
    updateAndSaveSSOStatus,
}) => {

    const handleUpdateAndSaveSSOStatus = (enabled) => {
        if (updateAndSaveSSOStatus) {
            updateAndSaveSSOStatus(enabled)
        }
    }

    return (
        <SettingsCard
            headerText={'Single Sign On'}
        >
            <Typography style={{ fontWeight: 600, paddingTop: 10 }} color="textPrimary" variant="body2">
                Toggle whether SSO for end user reporting is enabled.
            </Typography>
            <Typography color="textPrimary" variant="body2">
                <strong>WARNING: Do not modify without confirming changes with your account manager.</strong>
            </Typography>
            <FormControl component="fieldset">
                <RadioGroup row value={ssoEnabled}
                    onChange={(elem) => handleUpdateAndSaveSSOStatus(elem.target.value)}>
                    <FormControlLabel value="true" control={<Radio color="primary" />} label={
                        <>
                            <Typography style={{ fontWeight: 600 }} color="textPrimary" variant="body2">
                                Enabled
                            </Typography>
                        </>
                    } />
                    <FormControlLabel value="false" control={<Radio color="primary" />} label={
                        <>
                            <Typography style={{ fontWeight: 600 }} color="textPrimary" variant="body2">
                                Disabled
                            </Typography>
                        </>
                    } />
                </RadioGroup>
            </FormControl>
        </SettingsCard>
    )
}
SSOCard.propTypes = {
    ssoEnabled: PropTypes.string,
    updateAndSaveSSOStatus: PropTypes.func,
}

export default SSOCard