import React from 'react'
import PropTypes from 'prop-types'
import SettingsCard from '@mc/common/components/Cards/SettingsCard'
import TagInput from '@mc/common/components/Input/TagInput'
import { EMPLOYEE_TYPE } from '../TrackersTab'
import { validateEmail } from '@mc/common/lib/strings'


const KeyEmployeesCard = ({
    addTag,
    removeTag,
    employees,
}) => {

    const handleDeleteEmployee = (employee) => {
        if (removeTag) {
            removeTag(EMPLOYEE_TYPE, employee)
        }
    }

    const handleAddEmployee = (employee) => {
        let emailInput = employee.trim().toLowerCase()
        if (addTag) {
            addTag(EMPLOYEE_TYPE, emailInput)
        }

    }

    return (
        <SettingsCard
            headerText={'Key Employees'}
            regularDescriptionText={'Add up to 10 employees to track on the Trackers dashboard. Tracking for these items begins as soon as you add them here. You can input comma separated values too like jdoe@abc.com, jndoe@abc.com.'}
        >
            <TagInput
                id='key_employees'
                placeholder={'e.g. jdoe@abc.com, jndoe@abc.com'}
                tags={employees}
                addTag={handleAddEmployee}
                deleteTag={handleDeleteEmployee}
                invalidInputMessage={'Not a valid email.'}
                validateInput={validateEmail}

            />
        </SettingsCard>
    )
}
KeyEmployeesCard.propTypes = {
    addTag: PropTypes.func,
    removeTag: PropTypes.func,
    employees: PropTypes.array,
}

export default KeyEmployeesCard
