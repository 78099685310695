import React from 'react'
import PageHeader from '@mc/common/components/Page/PageHeader'
import { Box, Snackbar } from '@material-ui/core'
import Tabs from '@mc/common/components/Tabs/Tabs'
import EmailAlertsTab from './tabs/EmailAlertsTab'
import TrackersTab from './tabs/TrackersTab'
import NotificationTab from './tabs/NotificationTab'
import MuiAlert from '@material-ui/lab/Alert'
import GraphRulePrefsContextProvider from './tabs/EmailAlertsTabCards/GraphRulePreferencesContext'
import InfoTab from './tabs/InfoTab'



const Trackers = ({ locationHash }) => {

    const [currentTab, setTab] = React.useState('dynamic-banners')
    const [toastOpen, setToastOpen] = React.useState(false)

    React.useEffect(() => {
        if (locationHash !== '') {
            setTab(locationHash.split('#')[1])
        }
        if (locationHash === '') {
            window.location.hash = 'dynamic-banners'
            setTab('dynamic-banners')
        }
    }, [locationHash])


    const tabOptions = [
        { value: 'dynamic-banners', label: 'Dynamic Banners', },
        { value: 'trackers', label: 'Trackers', },
        { value: 'notifications', label: 'Notifications' },
        { value: 'info', label: 'Info' },

    ]
    const handleChangeTableTab = (value) => {
        let newTab = value || 'dynamic-banners'
        window.location.hash = newTab
        setTab(newTab)
    }

    const handleCloseToast = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setToastOpen(false)
    }

    return (
        <>
            <PageHeader pageTitle="Settings" subText="Customer app settings" />
            <Snackbar open={toastOpen} autoHideDuration={6000} onClose={handleCloseToast}>
                <MuiAlert elevation={6} variant="filled" severity="success" onClose={handleCloseToast}>
                    Settings Updated!
                </MuiAlert >
            </Snackbar>
            <Box p={2} style={{ padding: '0px 0px 30px 0px' }}>
                <Tabs style={{ fontSize: '16px' }} value={currentTab} onChange={handleChangeTableTab} options={tabOptions} />
            </Box>
            {currentTab === 'dynamic-banners' && <GraphRulePrefsContextProvider>
                    <EmailAlertsTab triggerToast={() => setToastOpen(true)} /> 
                </GraphRulePrefsContextProvider>}
            {currentTab === 'trackers' && <TrackersTab triggerToast={() => setToastOpen(true)} />}
            {currentTab === 'notifications' && <NotificationTab triggerToast={() => setToastOpen(true)} />}
            {currentTab === 'info' && <InfoTab/>}
        </>
    )
}

export default Trackers
